



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsCmsCat9 extends Vue {
  swiperData: object[] = [];

  swiperOption: object = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true, // 修改swiper的父元素时，自动初始化swiper
    slidesPerView: this.slidesView,
    slidesPerGroup: 5,
    spaceBetween: 10
  };
  // 获取排序后content结果
  getCMSDictionariesSort () {
    this.$Api.cms
      .getCMSDictionariesSort({ CatId: 40143 })
      .then(result => {
        result.forEach((item, index) => {
          if (item.DictionariesSorts && item.DictionariesSorts.length > 0) {
            this.swiperData.push(item);
          }
        });
        console.log(this.swiperData, ' 会员名册资料');
      });
  }
  // 錨點
  goAnchor (e, selector) {
    console.log(selector, '12340');
    $(e.target).css({ 'color': '#f36f21', 'font-size': '22px' });
    $(e.target).parent('.swiper-slide').siblings('.swiper-slide').find('a').css({ 'color': '#999', 'font-size': '16px' });
    let anchor = this.$el.querySelector(selector);
    let difH = this.isMobile ? 100 : 150;
    document.documentElement.scrollTop = anchor.offsetTop - difH;
  }
  get slidesView () {
    return this.isMobile ? 6 : 26;
  }
  get swiper () {
    return (this as any).$refs.mySwiper.swiper;
  }

  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  stopLoop () {
    this.swiper.autoplay.stop();
  }
  mounted () {
    this.stopLoop();
    this.getCMSDictionariesSort();
  }
}
